import { tlang, WebModuleLitTable, WebModuleLitTableColumnDef, WebmoduleToggle } from '@softtech/webmodule-components';
import { Snippet, PromiseTemplate } from '@softtech/webmodule-data-contracts';
import { html } from 'lit-html';
import { isAutoSaving } from '../../components/save-workflow';
import { PageControlTabWithIndependantSaving } from '../../components/ui/data-entry-screen-base';
import { DataTracker, DynamicValueBinder, FieldType } from '../../components/ui/databinding/data-tracker';
import { DataBinding } from '../../components/ui/databinding/databinding';
import { fireQuickSuccessToast } from '../../toast-away';
import { isEmptyOrSpace } from '../../components/ui/helper-functions';
import { FileContainer, FileContainerManager } from '../data/file-content-container';
import { Editor } from '@toast-ui/editor';
import { getSettingsManager } from '../../supplier/common/supplier-settings';
import { SupplierSettingsManager } from '../../supplier/common/supplier-settings-manager';
import { stringToBase64 } from '../../blob/converters';
import { SupplierSettings } from '../../api/supplier-api-interface-supplier';

export class SettingsGeneralView extends PageControlTabWithIndependantSaving {
  private settingsManager: SupplierSettingsManager = getSettingsManager();
  private dataBinding: DataBinding;
  private dataTracker: DataTracker;

  private maxTableConfigCount: number;

  private termsAndContitionsManager: FileContainerManager;
  private editor?: Editor;

  private getFieldCode(index): string {
    return `quote_item_config_code_${index}`;
  }
  private getFieldDisplayed(index): string {
    return `quote_item_config_display_${index}`;
  }
  private getFieldCanToggle(index): string {
    return `quote_item_config_toggle_${index}`;
  }

  constructor() {
    super();
    this.pageFragment = 'general';

    this.dataBinding = new DataBinding(this.ui, this.elementId, input => {
      return `${input}-${this.elementId}`;
    });
    this.dataBinding.allowMissingElements = true; // Removed Config items
    this.dataTracker = new DataTracker(this.dataBinding);

    this.termsAndContitionsManager = new FileContainerManager(
      new FileContainer(this.settingsManager.supplierSettings.termsAndConditionsVirtualPath),
      () => tlang`Terms & Conditions`
    );
    this.termsAndContitionsManager.afterSave.push(async () => {
      this.settingsManager.updateInput.base64TermsAndConditions = stringToBase64(
        this.termsAndContitionsManager.fileContent
      );
      await this.settingsManager.saveSettings();
      await this.render();
    });
    const addField = (
      fieldName: string,
      propertyType?: FieldType,
      nullable?: boolean,
      editorFieldName?: string,
      data?: () => any
    ) => {
      this.dataTracker.addObjectBinding(
        data ?? (() => this.settings),
        fieldName,
        editorFieldName ?? fieldName,
        propertyType ?? FieldType.string,
        nullable ?? false
      );
    };

    addField('validityPeriod', FieldType.int);
    addField('leadPeriod', FieldType.int);

    this.maxTableConfigCount = this.settings.quoteItemTableConfiguration?.length ?? 0;
    for (let i = 0; i < this.maxTableConfigCount; i++) this.addTableConfig(i);
  }

  public async afterConstruction(): Promise<void> {
    await this.render();
  }

  public allowDeletePage(): boolean {
    return false;
  }
  protected getCaption(): Snippet {
    return tlang`General Settings`;
  }

  getValidationErrors(): string[] {
    const errors: string[] = [];

    const validityPeriod = this.dataBinding.getInt('validityPeriod');
    const leadPeriod = this.dataBinding.getInt('leadPeriod');
    const tacText = this.editor?.getMarkdown();

    if (validityPeriod == null || validityPeriod < 0) {
      errors.push(tlang`Validity Period must be greater than or equal to 0`);
    }

    if (leadPeriod == null || leadPeriod < 0) {
      errors.push(tlang`Lead Period must be greater than or equal to 0`);
    }

    if (isEmptyOrSpace(tacText)) {
      errors.push(tlang`No Terms And Condition specified`);
    }

    if (
      this.settings.quoteItemTableConfiguration &&
      this.settings.quoteItemTableConfiguration.length > 0 &&
      !this.settings.quoteItemTableConfiguration?.every(x => !isEmptyOrSpace(x.code))
    ) {
      errors.push(tlang`Specify a Code for each row in the Quote Item Summary Configuration section`);
    }

    return errors;
  }

  async prepareForSave(): Promise<void> {
    if (this.dataTracker.modified) {
      this.dataTracker.applyChangeToValue();
    }

    this.termsAndContitionsManager.fileContent = this.editor?.getMarkdown() ?? '';
  }
  public internalDataChanged(): boolean {
    return this.settingsManager.changed() || this.termsAndContitionsManager.changed();
  }

  async onEnter(): Promise<void> {
    await this.settingsManager.needsSettings(true);
    await this.settingsManager.needsSystemConfiguration();

    await this.termsAndContitionsManager.needsFile();

    const element = document.querySelector('#generalSettingsEditor') as HTMLElement | null;
    if (element) {
      this.editor = new Editor({
        el: element,
        previewStyle: 'vertical',
        height: '500px',
        initialValue: this.termsAndContitionsManager.fileContent,
        usageStatistics: false,
        initialEditType: 'wysiwyg'
      });
    }

    await this.render();
  }

  get settings(): SupplierSettings {
    return this.settingsManager.supplierSettings;
  }

  protected async internalSaveData(): Promise<boolean> {
    await this.termsAndContitionsManager.save(true);

    const result = await this.settingsManager.saveSettings();
    if (result) {
      if (!isAutoSaving()) fireQuickSuccessToast(tlang`${this.getCaption()} saved`);
      await this.render();
      return true;
    }

    return false;
  }

  addTableConfig(index: number) {
    this.dataTracker.addBinding(
      new DynamicValueBinder(
        FieldType.string,
        false,
        () => {
          if (!this.settings.quoteItemTableConfiguration || index >= this.settings.quoteItemTableConfiguration.length)
            return '';
          return this.settings.quoteItemTableConfiguration[index].code;
        },
        value => {
          if (!this.settings.quoteItemTableConfiguration || index >= this.settings.quoteItemTableConfiguration.length)
            return;
          this.settings.quoteItemTableConfiguration[index].code = (value as string) ?? '';
        },
        () => false
      ),
      this.getFieldCode(index),
      this.getFieldCode(index),
      FieldType.string,
      false
    );

    this.dataTracker.addBinding(
      new DynamicValueBinder(
        FieldType.boolean,
        false,
        () => {
          if (!this.settings.quoteItemTableConfiguration || index >= this.settings.quoteItemTableConfiguration.length)
            return false;
          return this.settings.quoteItemTableConfiguration[index].defaultDisplay;
        },
        value => {
          if (!this.settings.quoteItemTableConfiguration || index >= this.settings.quoteItemTableConfiguration.length)
            return false;
          this.settings.quoteItemTableConfiguration[index].defaultDisplay = (value as boolean) ?? false;

          return this.settings.quoteItemTableConfiguration[index].defaultDisplay;
        },
        () => false
      ),
      this.getFieldDisplayed(index),
      this.getFieldDisplayed(index),
      FieldType.boolean,
      false
    );

    this.dataTracker.addBinding(
      new DynamicValueBinder(
        FieldType.boolean,
        false,
        () => {
          if (!this.settings.quoteItemTableConfiguration || index >= this.settings.quoteItemTableConfiguration.length)
            return false;
          return this.settings.quoteItemTableConfiguration[index].canSwitch;
        },
        value => {
          if (!this.settings.quoteItemTableConfiguration || index >= this.settings.quoteItemTableConfiguration.length)
            return false;
          this.settings.quoteItemTableConfiguration[index].canSwitch = (value as boolean) ?? false;

          return this.settings.quoteItemTableConfiguration[index].canSwitch;
        },
        () => false
      ),
      this.getFieldCanToggle(index),
      this.getFieldCanToggle(index),
      FieldType.boolean,
      false
    );
  }

  protected async bodyTemplate(): PromiseTemplate {
    const toggleCustomItem = (e: CustomEvent) => {
      const target = e.target as WebmoduleToggle;
      this.settings.allowCustomItem = target.checked;
    };

    const toggleItemInArray = (array: number[], item: number, add: boolean) => {
      if (add) {
        if (!array.includes(item)) {
          array.push(item);
        }
      } else {
        const index = array.indexOf(item);
        if (index !== -1) {
          array.splice(index, 1);
        }
      }
    };

    const toggleEstimateType = (e: CustomEvent) => {
      const target = e.target as WebmoduleToggle;
      toggleItemInArray(this.settings.allowedQuoteTypes, 1, target.checked);
    };

    const toggleDisplaySampleType = (e: CustomEvent) => {
      const target = e.target as WebmoduleToggle;
      toggleItemInArray(this.settings.allowedQuoteTypes, 4, target.checked);
    };

    const toggleWarrantyItemType = (e: CustomEvent) => {
      const target = e.target as WebmoduleToggle;
      toggleItemInArray(this.settings.allowedQuoteTypes, 8, target.checked);
    };

    const addTableConfigEvent = () => {
      if (!this.settings.quoteItemTableConfiguration) this.settings.quoteItemTableConfiguration = [];

      this.settings.quoteItemTableConfiguration.push({
        code: '',
        title: '',
        canSwitch: true,
        defaultDisplay: true,
        systemDefault: false
      });

      const currentCount = this.settings.quoteItemTableConfiguration.length ?? 0;

      if (currentCount > this.maxTableConfigCount) {
        this.addTableConfig(this.maxTableConfigCount);
        this.maxTableConfigCount = currentCount;
      }

      this.render();
    };

    const colDef = this.getColums();

    const addSummaryTable = () => {
      if (!this.settings.quoteItemTableConfiguration || this.settings.quoteItemTableConfiguration.length == 0)
        return html``;

      return html`
        <webmodule-lit-table
          id="settings-quote-item-config-table"
          class="settings-quote-item-config-table"
          .rowClass=${'tr'}
          .colClass=${'column'}
          .tablestyle="nestedtable"
          .columns=${colDef}
          .pageLength="100"
          .data=${this.settings.quoteItemTableConfiguration ?? []}
          .clickrows=${false}
        >
        </webmodule-lit-table>
      `;
    };

    const addSummaryConfigButtonTemplate = () => {
      return html` <div class="row">
        <div class="col-2 mb-3 mt-3">
          <webmodule-button variant="primary" ?disabled=${this.isReadonly()} @click=${addTableConfigEvent}>
            ${tlang`Add Column Configuration`}
          </webmodule-button>
        </div>
      </div>`;
    };

    return html` <div>
      <form id="formGeneralSettings" class="form-one-col">
        <h2>${tlang`%%quote%% Settings`}:</h2>
        <div class="row">
          <div class="col-md-6">
            <webmodule-input
              class="label-on-left"
              id=${this.dataBinding.field('validityPeriod')}
              .value=${this.dataTracker.getObjectDisplayValue('validityPeriod')}
              label=${tlang`Validity Period (days)` + ':'}
              size="small"
              min="0"
              max="365"
              type="number"
              required
            >
            </webmodule-input>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <webmodule-input
              class="label-on-left"
              id=${this.dataBinding.field('leadPeriod')}
              .value=${this.dataTracker.getObjectDisplayValue('leadPeriod')}
              label=${tlang`Lead Period (weeks)` + ':'}
              size="small"
              min="0"
              max="52"
              type="number"
              required
            >
            </webmodule-input>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 form-column form-toggle-labled">
            <div class="row mb-2 mt-1 form-col-item">
              <label class="form-col-label">Supported Quote Types: </label>
              <div class="form-col-input">
                <webmodule-toggle
                  size="small"
                  class="me-3"
                  data-id=${this.dataBinding.field('estimate')}
                  ?checked=${this.settings.allowedQuoteTypes.includes(1)}
                  @webmodule-change=${toggleEstimateType}
                >
                  ${tlang`%%estimate%%`}
                </webmodule-toggle>

                <webmodule-toggle
                  size="small"
                  class="me-3"
                  data-id=${this.dataBinding.field('displaysample')}
                  ?checked=${this.settings.allowedQuoteTypes.includes(4)}
                  @webmodule-change=${toggleDisplaySampleType}
                >
                  ${tlang`%%display-sample%%`}
                </webmodule-toggle>

                <webmodule-toggle
                  size="small"
                  class="me-3"
                  data-id=${this.dataBinding.field('warrantyitem')}
                  ?checked=${this.settings.allowedQuoteTypes.includes(8)}
                  @webmodule-change=${toggleWarrantyItemType}
                >
                  ${tlang`%%warranty-item%%`}
                </webmodule-toggle>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 form-column form-toggle-labled">
            <div class="row mb-2 mt-2 form-col-item">
              <label class="form-col-label">Allow Custom Item Request: </label>

              <div class="form-col-input">
                <webmodule-toggle
                  size="small"
                  data-id=${this.dataBinding.field('allowCustomItem')}
                  ?checked=${this.settings.allowCustomItem}
                  @webmodule-change=${toggleCustomItem}
                >
                </webmodule-toggle>
              </div>
            </div>
          </div>
        </div>

        <h2>${tlang`%%quote-item%% Summary Configuration`}:</h2>
        ${addSummaryTable()} ${addSummaryConfigButtonTemplate()}

        <h2>${tlang`%%purchase-order%% Terms & Conditions`}:</h2>
        <div class="row">
          <div id="generalSettingsEditor"></div>
        </div>
      </form>
    </div>`;
  }

  async applyBinding() {
    await this.prepareForSave();
  }

  private getColums(): WebModuleLitTableColumnDef[] {
    const cols: WebModuleLitTableColumnDef[] = [];
    cols.push({
      title: tlang`Title`,
      classes: 'colpxmax-100 no-pseudo quote-item-summary-config-code',
      fieldName: 'xx',
      displayValue: (_table: WebModuleLitTable, _item: unknown, index: number) => {
        const field = this.getFieldCode(index);

        const options = () => {
          if (
            this.settingsManager.systemSettings &&
            this.settingsManager.systemSettings.supplierQuoteItemConfigurations.length > 0
          )
            return this.settingsManager.systemSettings.supplierQuoteItemConfigurations
              .filter(x => !x.systemDefault)
              .map(x => html`<webmodule-option value="${x.code}">${x.title}</webmodule-option>`);
          return [];
        };

        return html`
          <div class="col-md-12">
            <webmodule-select
              size="small"
              required
              id=${this.dataBinding.field(field)}
              placeholder="Select column"
              hoist
              value=${this.dataTracker.getObjectDisplayValue(field) ?? ''}
              @webmodule-change=${() => this.applyBinding()}
            >
              ${options()}
            </webmodule-select>
          </div>
        `;
      }
    });

    cols.push({
      title: tlang`Default Display`,
      classes: 'colpxmax-100 quote-item-summary-config-display',
      fieldName: 'xx',
      displayValue: (_table: WebModuleLitTable, _item: unknown, index: number) => {
        const field = this.getFieldDisplayed(index);

        const toggleDisplay = async (e: CustomEvent) => {
          const target = e.target as WebmoduleToggle;

          this.dataBinding.setValue(field, target.checked.toString());
          target.value = target.checked.toString();
          await this.prepareForSave();
        };

        return html`
          <webmodule-toggle
            id=${this.dataBinding.field(field)}
            ?checked=${this.dataTracker.getObjectValue(field)}
            value=${this.dataTracker.getObjectValue(field)}
            size="small"
            @webmodule-change=${toggleDisplay}
          >
          </webmodule-toggle>
        `;
      }
    });
    cols.push({
      title: tlang`Dealer can show or hide`,
      classes: 'colpxmax-100 quote-item-summary-config-toggle',
      fieldName: 'xx',
      displayValue: (_table: WebModuleLitTable, _item: unknown, index: number) => {
        // const rowItem = item as QuoteItemSummaryConfig;
        const field = this.getFieldCanToggle(index);

        const toggleCanToggle = async (e: CustomEvent) => {
          const target = e.target as WebmoduleToggle;

          this.dataBinding.setValue(field, target.checked.toString());
          target.value = target.checked.toString();
          await this.prepareForSave();
        };

        return html`
          <webmodule-toggle
            id=${this.dataBinding.field(field)}
            ?checked=${this.dataTracker.getObjectValue(field)}
            value=${this.dataTracker.getObjectValue(field)}
            size="small"
            @webmodule-change=${toggleCanToggle}
          >
          </webmodule-toggle>
        `;
      }
    });

    cols.push({
      title: tlang``,
      classes: 'colpxmax-100 quote-item-summary-config-action',
      fieldName: 'xx',
      displayValue: (_table: WebModuleLitTable, _item: unknown, index: number) => {
        const removeConfig = () => {
          this.settings.quoteItemTableConfiguration?.splice(index, 1);
          this.render();
        };

        return html` <div class="settings-quote-item-summary-actions">
          <webmodule-icon-button library="fa" name="fas-trash-can" @click=${removeConfig}></webmodule-icon-button>
        </div>`;
      }
    });

    return cols;
  }
}
