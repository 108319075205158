import { tlang } from '@softtech/webmodule-components';
import { DataTableWrapper, RequestPage } from '../../components/ui/datatable-view';
import { ResultPaginated } from '../../paginated_result';

import { getPagedDataFromArray } from '../data/settings-helpers';
import { PricingRule, PricingRuleType } from '../../api/supplier-api-interface-supplier';

export type GetPricingRules = () => PricingRule[];
export type GetPricingRuleTypes = () => PricingRuleType[];

export interface SettingsPricingRulesDataTableBaseOptions {
  getPricingRules: GetPricingRules;
  getRuleType: (id: string) => PricingRuleType | null;
}

export class SettingsPricingRulesDataTableBase extends DataTableWrapper<PricingRule> {
  protected getPricingRules: GetPricingRules;
  protected getRuleType: (id: string) => PricingRuleType | null;

  constructor(options: SettingsPricingRulesDataTableBaseOptions) {
    super();

    this.getPricingRules = options.getPricingRules;
    this.getRuleType = options.getRuleType;
  }

  async getRowsFromServer(request: RequestPage): Promise<ResultPaginated<PricingRule>> {
    const compareFunction = (a: PricingRule, b: PricingRule) => {
      return a.name.localeCompare(b.name, undefined, { numeric: true });
    };
    const pricingRules = this.getPricingRules().sort(compareFunction);
    const result = getPagedDataFromArray(pricingRules, request);
    return result;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getColumns(): any[] {
    return [
      {
        title: tlang`Rule Name`,
        width: '90px',
        data: 'name',
        render: (value: string, _type, _row: PricingRule) => value
      },
      {
        title: tlang`Rule Type`,
        width: '90px',
        data: 'ruleTypeId',
        orderable: false,
        render: (value: string, _type, _row: PricingRule) => {
          return this.getRuleTypeName(value);
        }
      },
      {
        title: tlang`Value`,
        width: '90px',
        data: 'minValue',
        orderable: false,
        render: (_value: string, _type, row: PricingRule) => {
          return this.getPricingRuleValue(row);
        }
      }
    ];
  }

  protected getRuleTypeName(ruleTypeId: string): string {
    return this.getRuleType(ruleTypeId)?.name ?? 'Invalid Rule Type';
  }

  protected getPricingRuleValue(pricingRule: PricingRule): string {
    if (pricingRule.minValue > 0) {
      if (pricingRule.maxValue > 0) {
        return `${pricingRule.minValue}% - ${pricingRule.maxValue}%`;
      }
      return `${pricingRule.minValue}%`;
    } else if (pricingRule.maxValue > 0) {
      return `${pricingRule.maxValue}%`;
    }
    return 'Invalid min/max values';
  }

  useAutoWidthColumns(): boolean {
    return true;
  }
}
