import { Snippet, PromiseTemplate } from '@softtech/webmodule-data-contracts';
import { html } from 'lit';
import { PageControlTabWithIndependantSaving } from '../../components/ui/data-entry-screen-base';
import { tlang } from '@softtech/webmodule-components';
import { lockUIandExecute } from '../../ui-lock';
import { constructAsync } from '../../async-constructor';
import { emptyGuid, newGuid } from '../../api/guid';

import { AskConfirmation, confirmationButtons, ConfirmationButtonType } from '../../components/ui/modal-confirmation';
import { PricingRulesManager } from '../data/pricing-rules-manager';
import { PricingRuleEditModal } from './pricing-rule-edit-modal';
import {
  SettingsPricingRulesDataTableBase,
  SettingsPricingRulesDataTableBaseOptions
} from './settings-pricing-rules-table-base';
import { PricingRule } from '../../api/supplier-api-interface-supplier';
import { getSettingsManager } from '../../supplier/common/supplier-settings';

export type PricingRuleEvent = (pricingRule: PricingRule) => Promise<void>;

export interface SettingsPricingRulesDataTableOptions extends SettingsPricingRulesDataTableBaseOptions {
  editPricingRule: PricingRuleEvent;
  deletePricingRule: PricingRuleEvent;
}

export class SettingsPricingRulesDataTable extends SettingsPricingRulesDataTableBase {
  private editPricingRule: PricingRuleEvent;
  private deletePricingRule: PricingRuleEvent;

  constructor(options: SettingsPricingRulesDataTableOptions) {
    super(options);

    this.editPricingRule = options.editPricingRule;
    this.deletePricingRule = options.deletePricingRule;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getColumns(): any[] {
    return [
      {
        title: tlang`Rule Name`,
        width: '90px',
        data: 'name',
        render: (value: string, _type, _row: PricingRule) => value
      },
      {
        title: tlang`Rule Type`,
        width: '90px',
        data: 'ruleTypeId',
        render: (value: string, _type, _row: PricingRule) => {
          return this.getRuleTypeName(value);
        }
      },
      {
        title: tlang`Value`,
        width: '90px',
        data: 'minValue',
        render: (_value: string, _type, row: PricingRule) => {
          return this.getPricingRuleValue(row);
        }
      },
      {
        title: '...',
        data: 'id',
        width: '100px',
        orderable: false,
        render: (_value: number, _type, _row: PricingRule) => {
          return this.menu();
        },
        className: 'item-menu'
      }
    ];
  }

  protected menu(): string {
    const template = `
        <div class="multi-actions">
            <input type="checkbox" >
            <label>
                <button type="button" class="multi-action-btn btn-circle ">
                    <img class="icon action-open" src="/assets/icons/edit.svg">
                </button>
                <button type="button" class="multi-action-btn btn-circle">
                    <img class="icon action-delete" src="/assets/icons/bin.svg">
                </button>
                <span class="multi-action-btn btn-circle">
                    <img class="icon" src="/assets/icons/close.svg" onclick="checkClosest(this,false)">
                </span>
                <span class="icon" >
                    <img src="/assets/icons/ellipse.svg" onclick="checkClosest(this,true)">
                </span>
            </label>
        </div>`;
    return template;
  }

  protected bindClickEvents($dataTable: any): void {
    $dataTable.on(
      'click',
      '.action-delete',
      this.eventHandler(async (data: PricingRule) => {
        if (
          await AskConfirmation(
            tlang`Are you sure you'd like to delete the %%pricing-rule%%?`,
            confirmationButtons[ConfirmationButtonType.yesNo]
          )
        )
          await this.deletePricingRule(data);
      })
    );
    $dataTable.on(
      'click',
      '.action-open',
      this.eventHandler(async (data: PricingRule) => {
        await this.editPricingRule(data);
      })
    );
  }
}

export interface SettingsPricingRulesViewOptions {
  globalSupplierId: number;
  pricingRulesVirtualPath: string;
}

export class SettingsPricingRulesView extends PageControlTabWithIndependantSaving {
  protected pricingRulesTable: SettingsPricingRulesDataTable;
  protected pricingRulesManager: PricingRulesManager;

  private _supplierId: number;

  constructor() {
    super();

    this.pageFragment = 'pricing-rules';

    this._supplierId = getSettingsManager().supplierSettings.globalSupplierId;

    this.pricingRulesManager = this.pricingRulesManagerFactory();

    this.pricingRulesTable = this.settingsPricingRulesTableFactory();
  }

  public async afterConstruction(): Promise<void> {
    await getSettingsManager().needsSettings(true);
  }

  protected getCaption(): Snippet {
    return tlang`!!pricing-rule!!`;
  }

  public allowDeletePage(): boolean {
    return false;
  }

  async prepareForSave(): Promise<void> {}

  protected async internalSaveData(): Promise<boolean> {
    const result = await this.pricingRulesManager.save(true);
    return result;
  }

  public internalDataChanged(): boolean {
    return this.pricingRulesManager.changed();
  }

  async onEnter(): Promise<void> {
    await this.refreshData();
  }

  get supplierId(): number {
    return this._supplierId;
  }

  public getValidationErrors(): string[] {
    const errors: string[] = [];
    return errors;
  }

  protected async refreshData() {
    await this.render();
  }

  buttonMenu(): Snippet | PromiseLike<Snippet> {
    const createNewPricingRuleEvent = async () => lockUIandExecute(async () => await this.createNewPricingRule());

    return html`
      <button @click=${createNewPricingRuleEvent} class="btn btn-primary" type="button">${tlang`Create Rule`}</button>
    `;
  }

  protected async bodyTemplate(): PromiseTemplate {
    this.pricingRulesTable.render();

    const createNewPricingRuleEvent = async () => lockUIandExecute(async () => await this.createNewPricingRule());

    return html` <div>
      <form id="OnboardingEmailForm" class="form-two-col">
        <h2>${this.getCaption()}:</h2>
        <div class="row">
          <div>${this.pricingRulesTable.ui}</div>
        </div>
        <div class="row">
          <div class="new-table-item">
            <button
              @click=${createNewPricingRuleEvent}
              class="btn btn-primary"
              type="button"
              id="btn-create-pricing-rule"
            >
              ${tlang`Create Rule`}
            </button>
          </div>
        </div>
      </form>
    </div>`;
  }

  //this can be overridden in sub class to replace the table with different column/view etc
  protected settingsPricingRulesTableFactory(): SettingsPricingRulesDataTable {
    return new SettingsPricingRulesDataTable({
      getPricingRules: () => this.pricingRulesManager.pricingRules,
      editPricingRule: async (pricingRule: PricingRule) => await this.editPricingRule(pricingRule),
      deletePricingRule: async (pricingRule: PricingRule) => await this.deletePricingrule(pricingRule),
      getRuleType: (id: string) => this.pricingRulesManager.getPricingRuleType(id)
    });
  }

  protected pricingRulesManagerFactory(): PricingRulesManager {
    return new PricingRulesManager();
  }

  protected async createNewPricingRule(): Promise<void> {
    const title = tlang`Add %%pricing-rule%%`;
    const pricingRule: PricingRule = {
      id: newGuid(),
      supplierId: this.supplierId,
      name: '',
      maxValue: 0,
      minValue: 0,
      ruleTypeId: emptyGuid
    };
    const pricingRuleModal = await constructAsync(
      new PricingRuleEditModal({
        pricingRule: pricingRule,
        modalTitle: () => title,
        saveButtonTitle: () => tlang`Save`,
        cancelButtonTitle: () => tlang`Cancel`,
        forceReadonly: false,
        pricingRuleTypes: this.pricingRulesManager!.pricingRuleTypes,
        getRuleType: this.pricingRulesManager!.getPricingRuleType
      })
    );
    await pricingRuleModal.showModal();
    if (pricingRuleModal.ok) {
      this.pricingRulesManager!.createPricingRule(pricingRule);
      this.pricingRulesTable.refreshData();
      this.render();
    }
  }

  protected async editPricingRule(pricingRule: PricingRule) {
    const title = tlang`Edit %%pricing-rule%%`;
    const pricingRuleModal = await constructAsync(
      new PricingRuleEditModal({
        pricingRule: pricingRule,
        modalTitle: () => title,
        saveButtonTitle: () => tlang`Save`,
        cancelButtonTitle: () => tlang`Cancel`,
        forceReadonly: false,
        pricingRuleTypes: this.pricingRulesManager!.pricingRuleTypes,
        getRuleType: this.pricingRulesManager!.getPricingRuleType
      })
    );
    await pricingRuleModal.showModal();
    if (pricingRuleModal.ok) {
      const pr = pricingRuleModal.pricingRule;
      this.pricingRulesManager!.editPricingRule(pr);
    }
  }

  protected async deletePricingrule(pricingRule: PricingRule) {
    this.pricingRulesManager!.deletePricingRule(pricingRule);
  }
}
