import { customElement, property } from 'lit/decorators.js';
import { getApiFactory } from '../../../api/api-injector';
import { tlang } from '@softtech/webmodule-components';
import { Snippet } from '@softtech/webmodule-data-contracts';
import { html } from 'lit';
import { DataTableWrapper, RequestPage } from '../../../components/ui/datatable-view';
import { ResultPaginated } from '../../../paginated_result';
import {
  PurchaseOrderSummary,
  ResultGetPurchaseOrderList
} from '../../../api/supplier-api-interface-franchiseenetwork';
import { serverDateTimeToLocal } from '../../../components/datetime-converter';
import { DashboardWidget } from './dashboard-widget';
import { DateTime } from 'luxon';
import { userDataStore } from '../../common/current-user-data-store';
import { getQuoteNumberDisplay } from '../../../dealer/data/quote-helper';
import { isEmptyOrSpace } from '../../../components/ui/helper-functions';
import { compare } from '../../../components/clone';
import { emptyGuid } from '../../../api/guid';

export class DeliveryPipelinePoTablePageCache {
  _pageKey = this.pageKey(-1, -1, '', false, undefined, undefined);
  _pagePromise: Promise<ResultGetPurchaseOrderList | null> | null = null;
  pageKey(
    index: number,
    length: number,
    sortField: string,
    sortAsc: boolean,
    branchId?: string,
    assignedToUserId?: string
  ): { index: number; length: number; sortField: string; sortAsc: boolean, branchId?: string, assignedToUserId?: string } {
    return { index, length, sortAsc, sortField, branchId: branchId, assignedToUserId };
  }

  async getData(index: number, length: number, sortField: string, sortAsc: boolean, branchId?: string, assignedToUserId?: string) {
    const currentPage = this.pageKey(index, length, sortField, sortAsc, branchId, assignedToUserId);
    if (!compare(this._pageKey, currentPage)) {
      this._pageKey = currentPage;
      this._pagePromise = getApiFactory().dataAggregation().getPurchaseOrderList({
        pageIndex: this._pageKey.index,
        pageSize: this._pageKey.length,
        sortField: this._pageKey.sortField,
        sortAsc: this._pageKey.sortAsc,
        branchId: this._pageKey.branchId ?? emptyGuid,
        assignedToUserId: this._pageKey.assignedToUserId ?? emptyGuid
      });
    }
    return await this._pagePromise;
  }
}
class DeliveryPipelinePoTable extends DataTableWrapper<PurchaseOrderSummary> {
  private _cache?: DeliveryPipelinePoTablePageCache | undefined;
  public get cache(): DeliveryPipelinePoTablePageCache | undefined {
    return this._cache;
  }
  public set cache(value: DeliveryPipelinePoTablePageCache | undefined) {
    this._cache = value;
    this.refreshData();
  }

  private _branchId?: string;
  public get branchId(): string | undefined {
    return this._branchId;
  }
  public set branchId(branchId: string | undefined) {
    this._branchId = branchId;
    this.refreshData();
  }

  private _assignedToUserId?: string;
  public get assignedToUserId(): string | undefined {
    return this._assignedToUserId;
  }
  public set assignedToUserId(userId: string | undefined) {
    this._assignedToUserId = userId;
    this.refreshData();
  }

  constructor(cache?: DeliveryPipelinePoTablePageCache) {
    super();
    this.cache = cache;
  }
  pageLength(): number {
    return 16;
  }

  getDefaultSortAsc(): boolean {
    return false;
  }

  protected ordering(): boolean {
    return false;
  }

  protected isNearDeadlineOrOverdue(_row: any, _data: PurchaseOrderSummary): boolean {
    const installDate = _data.supplierSystemDeliveryDate ?? _data.installationDate;
    const installationDate = installDate ? serverDateTimeToLocal(installDate) : null;

    if (installationDate != null && installationDate < DateTime.now().plus({ days: 3 }).toJSDate()) {
      return true;
    }

    return false;
  }

  public override afterRowCreated(_row: any, _data: PurchaseOrderSummary, _dataIndex: number): void {
    if (this.isNearDeadlineOrOverdue(_row, _data)) {
      $(_row).addClass('table-danger');
    }
  }

  async getRowsFromServer(request: RequestPage): Promise<ResultPaginated<PurchaseOrderSummary>> {
    const results = await this.cache?.getData(
      request.pageIndex,
      this.pageLength(),
      'SupplierSystemDeliveryDate',
      false,
      this.branchId,
      this.assignedToUserId
    );
    if (!results)
      return {
        count: 0,
        pageCount: 0,
        pageIndex: 0,
        pageSize: this.pageLength(),
        results: []
      };
    return {
      count: results.totalCount,
      pageCount: Math.ceil(results.totalCount / this.pageLength()),
      pageIndex: request.pageIndex,
      pageSize: this.pageLength(),
      results: results.purchaseOrders
    };
  }
  /**
   *
   * @param item
   * @returns standard dealer formatted quote number
   *
   */
  formatDealerQuoteNumber(item: PurchaseOrderSummary) {
    if (item.quoteNumber === 0) return tlang`<unavailable>`;
    return getQuoteNumberDisplay(item.quoteNumber, item.quoteSuffix);
  }

  getColumns(): any[] {
    return [
      {
        title: tlang`%%v6-reference%%`,
        width: '40px',
        data: 'reference',
        orderable: false,
        className: 'v6-reference',
        render: (_value: string, _type, item: PurchaseOrderSummary) => {
          //replaced code. reference is not a number. its a qualified formatted
          //string that holds a v6 quote num-pref-suff if v6 is the source
          //use build in function, matches dealer, and inbox
          return isEmptyOrSpace(item.reference) ? tlang`N/A` : item.reference;
        }
      },
      {
        title: tlang`%%purchase-order-supplier-system-status%%`,
        width: '100px',
        data: 'supplierSystemStatus',
        orderable: false,
        className: 'purchase-order-supplier-system-status',
        render: (value: string, _type, _row: PurchaseOrderSummary) => value
      },
      {
        title: tlang`%%franchisee%%`,
        width: '160px',
        data: 'customerName',
        orderable: false,
        className: 'dealership',
        render: (_value: string, _type, porderSummary: PurchaseOrderSummary) => {
          return this.lookupFranchiseeName(porderSummary.franchiseeTenantId);
        }
      },
      {
        title: tlang`%%branch%%`,
        width: '60px',
        data: 'branchName',
        orderable: false,
        className: 'branch',
        render: (_value: string, _type, porderSummary: PurchaseOrderSummary) => {
          return this.lookupBranchName(porderSummary.branchId);
        }
      },
      {
        title: tlang`%%purchase-order-description%%`,
        width: '160px',
        data: 'title',
        orderable: false,
        className: 'purchase-order-description',
        render: (value: string, _type, _row: PurchaseOrderSummary) => value
      },
      {
        title: tlang`%%purchase-order-supplier-delivery-date%%`,
        width: '40px',
        data: 'supplierSystemDeliveryDate',
        orderable: false,
        className: 'purchase-order-supplier-delivery-date',
        render: (value: string, _type, _row: PurchaseOrderSummary) => {
          return value == null ? '' : serverDateTimeToLocal(value).toLocaleDateString();
        }
      }
    ];
  }

  lookupFranchiseeName(franchiseeTenantId: number): string {
    return (
      userDataStore.allFranchiseeDetails.tenantList.tenants?.find(x => x.id === franchiseeTenantId)?.companyName ??
      tlang`<unavailable>`
    ); //this should never occur
  }

  lookupBranchName(branchId: string): string {
    return (
      userDataStore.allFranchiseeDetails.branches?.find(x => x.branchId === branchId)?.abbreviation ??
      tlang`<unavailable>`
    );
  }
}

@customElement('delivery-pipeline-po-widget')
export class DeliveryPipelinePoWidget extends DashboardWidget {
  @property()
  private _branchId?: string;
  public get branchId(): string | undefined {
    return this._branchId;
  }
  public set branchId(branchId: string | undefined) {
    this._branchId = branchId;
    this.dataTableInstance.branchId = branchId;
  }

  @property()
  private _assignedToUserId?: string;
  public get assignedToUserId(): string | undefined {
    return this._assignedToUserId;
  }
  public set assignedToUserId(userId: string | undefined) {
    this._assignedToUserId = userId;
    this.dataTableInstance.assignedToUserId = userId;
  }

  @property()
  private _cache?: DeliveryPipelinePoTablePageCache | undefined;
  public get cache(): DeliveryPipelinePoTablePageCache | undefined {
    return this._cache;
  }
  public set cache(value: DeliveryPipelinePoTablePageCache | undefined) {
    this._cache = value;
    this.dataTableInstance.cache = this.cache;
  }
  protected dataTableInstance: DeliveryPipelinePoTable;

  constructor() {
    super();
    this.dataTableInstance = new DeliveryPipelinePoTable(this.cache);
  }

  protected getHeader(): Snippet {
    return tlang`Delivery Pipeline`;
  }

  protected getBody(): Snippet {
    this.dataTableInstance.render();
    return html` <div class="delivery-pipeline-po-widget">${this.dataTableInstance.ui}</div> `;
  }
}
