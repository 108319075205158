import { tlang } from '@softtech/webmodule-components';
import { Snippet, PromiseTemplate } from '@softtech/webmodule-data-contracts';
import { html } from 'lit';
import { newGuid } from '../../api/guid';
import { PageControlTabWithIndependantSaving } from '../../components/ui/data-entry-screen-base';
import { DataTracker, FieldType } from '../../components/ui/databinding/data-tracker';
import { DataBinding } from '../../components/ui/databinding/databinding';
import { DataTableWrapper, RequestPage } from '../../components/ui/datatable-view';
import { isEmptyOrSpace } from '../../components/ui/helper-functions';
import { FormInputAssistant } from '../../components/ui/templateresult/form-input-assistant';
import { ResultPaginated } from '../../paginated_result';
import { lockUIandExecute } from '../../ui-lock';
import { AskConfirmation, ConfirmationButtonType, confirmationButtons } from '../../components/ui/modal-confirmation';
import { showValidations } from '../../components/ui/modal-validationhandler';
import { PurchaseOrderRecipientManager, PurchaseOrderRecipientManagerOptions } from '../data/purchase-order-manager';
import { equalsIgnoringCase, getPagedDataFromArray } from '../data/settings-helpers';
import { PurchaseOrderRecipient } from '../../api/supplier-api-interface-supplier';

export type GetPurchaseOrderRecipientEvent = () => PurchaseOrderRecipient[];
export type PurchaseOrderRecipientEvent = (recipient: PurchaseOrderRecipient) => Promise<void>;

export interface SettingsPurchaseOrdersRecipientsDataTableOptions {
  recipients: GetPurchaseOrderRecipientEvent;
  deleteRecipient: PurchaseOrderRecipientEvent;
}

export class SettingsPurchaseOrdersRecipientsDataTable extends DataTableWrapper<PurchaseOrderRecipient> {
  private deleteRecipient: PurchaseOrderRecipientEvent;
  private recipients: GetPurchaseOrderRecipientEvent;

  constructor(options: SettingsPurchaseOrdersRecipientsDataTableOptions) {
    super();
    this.deleteRecipient = options.deleteRecipient;
    this.recipients = options.recipients;
  }

  async getRowsFromServer(request: RequestPage): Promise<ResultPaginated<PurchaseOrderRecipient>> {
    const compareFunction = (a: PurchaseOrderRecipient, b: PurchaseOrderRecipient) => {
      return a.email.localeCompare(b.email, undefined, { numeric: true });
    };
    const recipients = this.recipients().sort(compareFunction);
    const result = getPagedDataFromArray(recipients, request);
    return result;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getColumns(): any[] {
    return [
      {
        title: tlang`Email`,
        width: '90px',
        data: 'email',
        render: (value: string, _type, _row: PurchaseOrderRecipient) => value
      },
      {
        title: '...',
        data: 'id',
        width: '100px',
        orderable: false,
        render: (_value: number, _type, _row: PurchaseOrderRecipient) => {
          return this.menu();
        },
        className: 'item-menu'
      }
    ];
  }

  protected menu(): string {
    const template = `
        <div class="multi-actions">
            <input type="checkbox" >
            <label>
                <button type="button" class="multi-action-btn btn-circle">
                    <img class="icon action-delete" src="/assets/icons/bin.svg">
                </button>
                <span class="multi-action-btn btn-circle">
                    <img class="icon" src="/assets/icons/close.svg" onclick="checkClosest(this,false)">
                </span>
                <span class="icon" >
                    <img src="/assets/icons/ellipse.svg" onclick="checkClosest(this,true)">
                </span>
            </label>
        </div>`;
    return template;
  }

  protected bindClickEvents($dataTable: any): void {
    $dataTable.on(
      'click',
      '.action-delete',
      this.eventHandler(async (data: PurchaseOrderRecipient) => {
        console.log(data);
        if (
          await AskConfirmation(
            tlang`Are you sure you'd like to delete the %%purchase-order%% %%recipient%%?`,
            confirmationButtons[ConfirmationButtonType.yesNo]
          )
        )
          await this.deleteRecipient(data);
      })
    );
  }

  useAutoWidthColumns(): boolean {
    return true;
  }
}

export class SettingsPurchaseOrdersRecipientsView extends PageControlTabWithIndependantSaving {
  private dataBinding: DataBinding;
  private dataTracker: DataTracker;
  private recipientsTable: SettingsPurchaseOrdersRecipientsDataTable;

  private newRecipientEmail: string = '';
  private recipientManager: PurchaseOrderRecipientManager;

  constructor() {
    super();
    this.pageFragment = 'order-recipients';

    this.dataBinding = new DataBinding(this.ui, this.elementId, input => {
      return `${input}-${this.elementId}`;
    });
    this.dataTracker = new DataTracker(this.dataBinding);

    this.recipientManager = this.recipientsManagerFactory();

    this.recipientsTable = this.settingsRecipientsTableFactory();

    const addField = (
      fieldName: string,
      propertyType?: FieldType,
      nullable?: boolean,
      editorFieldName?: string,
      data?: () => any
    ) => {
      this.dataTracker.addObjectBinding(
        data ?? (() => this),
        fieldName,
        editorFieldName ?? fieldName,
        propertyType ?? FieldType.string,
        nullable ?? false
      );
    };

    addField('newRecipientEmail', FieldType.string, true);
  }

  public async afterConstruction(): Promise<void> {
    await this.recipientManager.needsRecipients();
  }

  public allowDeletePage(): boolean {
    return false;
  }
  protected getCaption(): Snippet {
    return tlang`Purchase Order Recipients`;
  }

  public getValidationErrors(): string[] {
    const errors: string[] = [];
    return errors;
  }

  async prepareForSave(): Promise<void> {
    if (this.dataTracker.modified) {
      this.dataTracker.applyChangeToValue();
    }
  }
  public internalDataChanged(): boolean {
    console.log(`region internalDataChanged ${this.recipientManager.changed()}`);
    return this.recipientManager.changed();
  }

  async onEnter(): Promise<void> {
    await this.refreshData();
  }

  protected async internalSaveData(): Promise<boolean> {
    console.log('purchase order internalSaveData');
    const result = await this.recipientManager.save(true);
    return result;
  }

  protected async refreshData() {
    await this.render();
  }

  protected async bodyTemplate(): PromiseTemplate {
    const forms = new FormInputAssistant(this.dataTracker);
    this.recipientsTable.render();

    const createNewRecipientEvent = async () => lockUIandExecute(async () => await this.createNewRecipient());

    return html` <div>
      <form id="PurchaseOrderRecipientForm" class="form-two-col">
        <h2>${this.getCaption()}:</h2>
        <div class="row">
          <div class="col-sm-6 form-column">${this.recipientsTable.ui}</div>
        </div>
        <div class="row">
          <div class="col-sm-6 form-column new-table-item">
            ${forms.text('newRecipientEmail', tlang`Recipient Email`, 100)}
            <button
              @click=${createNewRecipientEvent}
              class="btn btn-primary shortstop-edit"
              type="button"
              id=${forms.id('create-po-recipient')}
            >
              ${tlang`Add`}
            </button>
          </div>
        </div>
      </form>
    </div>`;
  }

  //this can be overridden in sub class to replace the table with different column/view etc
  protected settingsRecipientsTableFactory(): SettingsPurchaseOrdersRecipientsDataTable {
    return new SettingsPurchaseOrdersRecipientsDataTable({
      recipients: () => this.recipients,
      deleteRecipient: async (recipient: PurchaseOrderRecipient) => await this.deleteRecipient(recipient)
    });
  }

  protected recipientsManagerFactory(): PurchaseOrderRecipientManager {
    const options: PurchaseOrderRecipientManagerOptions = {
      container: { recipients: [] }
    };
    return new PurchaseOrderRecipientManager(options);
  }

  protected get recipients(): PurchaseOrderRecipient[] {
    return this.recipientManager.recipients;
  }

  protected async createNewRecipient(): Promise<void> {
    const newRecipientEmail =
      this.dataTracker.getEditorValue('newRecipientEmail')?.toString() ?? this.newRecipientEmail;
    if (isEmptyOrSpace(newRecipientEmail)) {
      await showValidations([tlang`No email specified`]);
    } else {
      const hasExisting = this.recipients.find((value: PurchaseOrderRecipient) =>
        equalsIgnoringCase(newRecipientEmail, value.email)
      );
      if (hasExisting) {
        await showValidations([
          tlang`A %%purchase-order%% %%recipient%% already exists with the name '${newRecipientEmail}'`
        ]);
      } else {
        const recipient: PurchaseOrderRecipient = { id: newGuid(), email: newRecipientEmail };
        this.recipientManager.createRecipient(recipient);
        this.recipientsTable.refreshData();
      }
    }
    this.dataTracker.resetEditorValue();
    this.render();
  }

  protected async deleteRecipient(recipient: PurchaseOrderRecipient) {
    console.log(recipient);
    this.recipientManager.deleteRecipient(recipient);
    this.recipientsTable.refreshData();
  }
}
