import { WebModuleLitTable, WebModuleLitTableColumnDef, tlang } from '@softtech/webmodule-components';
import { html } from 'lit';
import { customElement, property, query, state } from 'lit/decorators.js';
import { Territory, TerritoryFranchiseeBranchLink } from '../../api/supplier-api-interface-supplier';
import { LitElementBase } from '../../components/litelement-base';
import { ICommandAction } from '../../components/ui/command-action';
import { userDataStore } from '../../supplier/common/current-user-data-store';
import { emptyGuid } from '../../api/guid';
import { TerritoryContainerManager } from '../data/territory-manager';
import { InputTriCheck, TriCheckState } from '../../components/ui/templatecontrols/input-tri-check';
import { isEmptyOrSpace } from '../../components/ui/helper-functions';

interface TerritoryFranchiseeView {
  territoryId: string;
  franchiseeId: string;
  franchiseeName: string;
  branches: TerritoryFranchiseeBranchView[];
  expanded: boolean;
  allSelected(): TriCheckState;
}

export interface TerritoryFranchiseeBranchView {
  id: string;
  branchId: string;
  branchName: string;
  isBranchLinked: boolean;
}

@customElement('webmodule-settings-regions-franchisee')
export class TerritoryFranchiseeTableElement extends LitElementBase {
  @query('#settings-branch-territories-table')
  table?: WebModuleLitTable;

  @state()
  private _selectedTerritory: Territory | null = null;
  public get selectedTerritory(): Territory | null {
    return this._selectedTerritory;
  }
  public set selectedTerritory(value: Territory | null) {
    this._selectedTerritory = value;
    this.convertData(); //fires off a promise
  }

  @state()
  private _territoryManager: TerritoryContainerManager | undefined;
  public get territoryManager(): TerritoryContainerManager | undefined {
    return this._territoryManager;
  }
  public set territoryManager(value: TerritoryContainerManager | undefined) {
    this._territoryManager = value;
  }

  @state()
  private _data: TerritoryFranchiseeView[] | undefined;
  private get data(): TerritoryFranchiseeView[] | undefined {
    return this._data;
  }
  private set data(value: TerritoryFranchiseeView[] | undefined) {
    this._data = value;
    this.table?.fetchEvent();
  }

  @property({ type: Boolean })
  public sectionsExpanded = false;

  /** This is called by the table when it wants new page data */
  private async internalDataLoad(e: CustomEvent) {
    const table = e.detail.table as WebModuleLitTable;

    const index = (e.detail?.pageIndex as number) ?? 0;

    const length = (e.detail?.pageLength as number) ?? 1000;

    const sortField = (e.detail?.sortField as string) ?? '';

    const sortAscending = (e.detail?.sortAscending as boolean) ?? true;
    if (!this.data) {
      table.data = [];
    }
    if (this.data) {
      const sortedData = this.data.sort((a, b) => {
        if (sortField === 'franchiseeName') {
          const result = a.franchiseeName.localeCompare(b.franchiseeName, undefined, { sensitivity: 'base' });
          return sortAscending ? result : result * -1;
        }
        return 0;
      });
      table.data = sortedData.slice(index * length, index * length + length);
      table.rowCount = sortedData.length;
    } else table.data = [];
  }

  async convertData() {
    await userDataStore.loadCoreDetails();
    const cacheData = userDataStore.allFranchiseeDetails;

    const results: TerritoryFranchiseeView[] = [];

    cacheData.tenantList.deployments?.forEach(viewDeployment => {
      viewDeployment.tenants?.forEach(tenantInfo => {
        const tenant = cacheData?.tenantList.tenants?.find(x => x.id === tenantInfo.tenantId);
        const franchiseeDeploymentConfiguration = cacheData?.franchisees.find(
          x => x.franchiseeTenantId == tenantInfo.tenantId && x.dealerDeploymentId == viewDeployment.dealerDeploymentId
        );
        const branches = cacheData?.branches.filter(
          x =>
            x.dealerDeploymentId == franchiseeDeploymentConfiguration?.dealerDeploymentId &&
            x.franchiseeTenantId == franchiseeDeploymentConfiguration?.franchiseeTenantId
        );

        const franchiseeId = franchiseeDeploymentConfiguration?.id ?? emptyGuid;
        const branchViews: TerritoryFranchiseeBranchView[] = [];
        const territory = this.selectedTerritory;

        branches.map(b => {
          const linkData = territory
            ? this.territoryManager?.getLinkByBranchAndTerritory(b.branchId, territory.id)
            : null;
          branchViews.push({
            id: linkData?.id ?? emptyGuid,
            branchId: b.branchId,
            branchName: b.name,
            isBranchLinked: linkData ? true : false
          });
        });

        results.push({
          territoryId: territory?.id ?? emptyGuid,
          franchiseeId: franchiseeId,
          franchiseeName: tenant?.companyName ?? '',
          branches: branchViews,
          expanded: this.sectionsExpanded,
          allSelected: () => this.getMultiCheckState(branchViews)
        });
      });
    });
    this.data = results;
  }

  private getMultiCheckState(branches: TerritoryFranchiseeBranchView[]): TriCheckState {
    const lengthAll = branches.length;
    const lengthChecked = branches.filter(b => b.isBranchLinked).length;
    if (lengthChecked == 0) {
      return TriCheckState.None;
    } else if (lengthChecked == lengthAll) {
      return TriCheckState.All;
    }
    return TriCheckState.Mixed;
  }

  render() {
    const keyEvent = (item: TerritoryFranchiseeView) => {
      return item.franchiseeId;
    };

    const expandAllTemplate = (view: TerritoryFranchiseeView) => {
      const expanderEventClick = async e => {
        e.stopImmediatePropagation();
        view.expanded = !view.expanded;
        this.table?.fetchEvent();
      };

      return html`<span @click=${expanderEventClick} class="franchisee-expand-branches">
        <i class="fa fa-solid fa-${view.expanded ? 'angle-up' : 'angle-down'}"></i>
      </span>`;
    };

    const checkAllTemplate = (view: TerritoryFranchiseeView) => {
      const linkAllEventClick = async (e: Event) => {
        const checkState = (e.currentTarget as InputTriCheck).checked;
        const allSelected = checkState == TriCheckState.All;
        if (allSelected) {
          await this.linkBranchesToTerritory(view.territoryId, view.branches);
        } else {
          await this.unlinkBranchesFromTerritory(view.territoryId, view.branches);
        }
        this.table?.fetchEvent();
      };
      const label = () => {
        switch (view.allSelected()) {
          case TriCheckState.All:
            return tlang`All !!branch!!`;
          case TriCheckState.Mixed:
            return tlang`Some !!branch!!`;
          case TriCheckState.None:
          default:
            return tlang`No !!branch!! Selected`;
        }
      };
      if (isEmptyOrSpace(view.territoryId)) {
        return html`<span>${label()}</span>`;
      }

      return html`<softtech-sui-input-tricheck
        @click=${linkAllEventClick}
        .checked=${view.allSelected()}
        .label=${label()}
      ></softtech-sui-input-tricheck>`;
    };

    const checkTemplate = (territoryId: string, view: TerritoryFranchiseeBranchView) => {
      const linkEventClick = async (_e: Event) => {
        if (view.isBranchLinked) {
          await this.unlinkBranchesFromTerritory(territoryId, [view]);
        } else {
          await this.linkBranchesToTerritory(territoryId, [view]);
        }
        this.table?.fetchEvent();
      };
      if (!this.selectedTerritory) {
        return html`<span>${view.branchName}</span>`;
      }

      return html`<softtech-sui-input-boolean
        @click=${linkEventClick}
        .checked=${view.isBranchLinked}
        .label=${view.branchName}
      ></softtech-sui-input-boolean>`;
    };

    const expandAllEventClick = async e => {
      e.stopImmediatePropagation();
      if (this.data) {
        const data = [...this.data];
        this.sectionsExpanded = !this.sectionsExpanded;
        data.forEach(t => (t.expanded = this.sectionsExpanded));
        this.data = data;
        this.table?.fetchEvent();
      }
    };

    const expanderTitle = html`<span @click=${expandAllEventClick} class="franchisee-expand-branches">
      <i class="fa fa-solid fa-${this.sectionsExpanded ? 'angle-up' : 'angle-down'} fa-fw"></i>
    </span>`;

    const expanderClickEvent = (e: Event, table: WebModuleLitTable, view: unknown) => {
      const rowView = view as TerritoryFranchiseeView;
      e.stopImmediatePropagation();
      rowView.expanded = !rowView.expanded;
      table.fetchEvent();
      return true;
    };

    const columns: WebModuleLitTableColumnDef[] = [];
    columns.push({
      title: expanderTitle,
      classes: 'col-max-px-40 align-items-start',
      fieldName: 'franchiseeId',
      click: expanderClickEvent,
      displayValue: (_table: WebModuleLitTable, item: unknown) => {
        const rowItem = item as TerritoryFranchiseeView;
        return expandAllTemplate(rowItem);
      }
    });
    columns.push({
      title: tlang`%%franchisee%%`,
      classes: 'colpx-200 align-items-start',
      fieldName: 'franchiseeName',
      sortable: true,
      click: expanderClickEvent,
      displayValue: (_table: WebModuleLitTable, item: unknown) => {
        const rowItem = item as TerritoryFranchiseeView;
        return html`<a role="button">${rowItem.franchiseeName}</a>`;
      }
    });
    columns.push({
      title: tlang`%%branch%%`,
      classes: 'colpx-200 align-items-start',
      fieldName: 'branches',
      displayValue: (_table: WebModuleLitTable, item: unknown) => {
        const rowItem = item as TerritoryFranchiseeView;
        return html`<div>
          ${checkAllTemplate(rowItem)}
          ${rowItem.expanded ? rowItem.branches.map(b => html`<br />${checkTemplate(rowItem.territoryId, b)}`) : ''}
        </div>`;
      }
    });

    return html` <div class="territory-regions">
      <webmodule-lit-table
        id="settings-branch-territories-table"
        .rowClass=${'tr'}
        .colClass=${'column'}
        .keyevent=${keyEvent}
        tablestyle="nestedtable"
        .columns=${columns}
        pageLength="100"
        @fetch=${(e: CustomEvent) => this.internalDataLoad(e)}
        .clickrows=${false}
        @fetchtemplate=${(e: CustomEvent) => {
          this._loadExtension(e);
        }}
      >
      </webmodule-lit-table>
    </div>`;
  }

  getActions(): ICommandAction[] {
    return [];
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  private _loadExtension(_e: CustomEvent) {}

  protected async linkBranchesToTerritory(territoryId: string, branchTerritories: TerritoryFranchiseeBranchView[]) {
    if (this.territoryManager) {
      const branchIdsToLink = branchTerritories.map(bt => bt.branchId);
      const result = await this.territoryManager.linkFranchiseeBranchesToTerritory(territoryId, branchIdsToLink);
      if (result) {
        branchTerritories.forEach(bt => (bt.isBranchLinked = true));
      }
    }
  }

  protected async unlinkBranchesFromTerritory(territoryId: string, branchTerritories: TerritoryFranchiseeBranchView[]) {
    if (this.territoryManager) {
      const manager = this.territoryManager;
      const links: TerritoryFranchiseeBranchLink[] = [];
      branchTerritories.forEach(bt => {
        const link = manager.getLinkByBranchAndTerritory(bt.branchId, territoryId);
        if (link) links.push(link);
      });
      if (links) {
        const result = await this.territoryManager.unlinkFranchiseeBranchesFromTerritory(links);
        if (result) {
          branchTerritories.forEach(bt => (bt.isBranchLinked = false));
        }
      }
    }
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'webmodule-settings-regions-franchisee': TerritoryFranchiseeTableElement;
  }
}
