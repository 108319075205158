import { tlang } from '@softtech/webmodule-components';
import { emptyGuid, newGuid } from '../../../api/guid';
import {
  PricingRulesManager,
  getMinMaxMarginPricingRuleType,
  getRegularRetailPricingRuleType
} from '../../../settings/data/pricing-rules-manager';
import { SettingsPricingRulesView } from '../../../settings/view/settings-pricing-rules-view';

import { PromiseTemplate, Snippet } from '@softtech/webmodule-data-contracts';
import { html } from 'lit';
import PricingRulesTableElement, { IPricingRulesTable } from '../ui/pricing-rules-table-element';
import { PricingRule, PricingRuleLibrary } from '../../../api/supplier-api-interface-supplier';

export class SupplierSettingsPricingRulesView extends SettingsPricingRulesView {
  protected newPricingRulesTable: IPricingRulesTable;

  constructor() {
    super();

    this.newPricingRulesTable = this.pricingRulesTableFactoryNew();
  }

  protected pricingRulesManagerFactory(): PricingRulesManager {
    return new PricingRulesManager();
  }

  protected getDefaultPricingRulesContainer(): PricingRuleLibrary {
    const rrpRuleType = getRegularRetailPricingRuleType();
    const minMaxRuleType = getMinMaxMarginPricingRuleType();

    const rrpRule: PricingRule = {
      id: newGuid(),
      minValue: 20,
      maxValue: 0,
      name: tlang`%%rrp-abbrev%%`,
      supplierId: this.supplierId,
      ruleTypeId: rrpRuleType.id
    };
    const minMaxRule: PricingRule = {
      id: newGuid(),
      minValue: 15,
      maxValue: 25,
      name: tlang`%%min%%/%%max%% Margin`,
      supplierId: this.supplierId,
      ruleTypeId: minMaxRuleType.id
    };

    return {
      pricingRules: [rrpRule, minMaxRule],
      pricingRuleTypes: [rrpRuleType, minMaxRuleType],
      defaultPricingRuleId: emptyGuid
    };
  }
  public async afterConstruction(): Promise<void> {
    await super.afterConstruction();
    if (this.pricingRulesManager.empty()) this.pricingRulesManager.container = this.getDefaultPricingRulesContainer();
  }
  public getValidationErrors(): string[] {
    const errors = super.getValidationErrors();

    for (const pricingRule of this.pricingRulesManager.pricingRules) {
      if (pricingRule.ruleTypeId) {
        const ruleType = this.pricingRulesManager.getPricingRuleType(pricingRule.ruleTypeId);
        if (ruleType && ruleType.hasMinMax && pricingRule.minValue >= pricingRule.maxValue) {
          errors.push(
            tlang`Maximum value cannot be less that or equal to Minimum for %%min%%/%%max%% %%pricing-rule%% '${pricingRule.name}'`
          );
        }
      }
    }

    return errors;
  }

  buttonMenu(): Snippet | PromiseLike<Snippet> {
    return html``;
  }

  protected async bodyTemplate(): PromiseTemplate {
    this.pricingRulesTable.render();

    return html` <div>
      <form id="PricingRulesForm" class="form-two-col">
        <h2>${this.getCaption()}:</h2>
        <div class="row">
          <div>${this.newPricingRulesTable.template()}</div>
        </div>
      </form>
    </div>`;
  }

  protected pricingRulesTableFactoryNew(): IPricingRulesTable {
    const table = new PricingRulesTableElement();
    table.dataSource = () => this.pricingRulesManager.container;
    table.updateEvent = async (pricingRule: PricingRule) => {
      this.pricingRulesManager.editPricingRule(pricingRule);
    };
    table.setDefaultRuleEvent = async (pricingRuleId?: string) => {
      this.pricingRulesManager.setDefaultPricingRuleId(pricingRuleId ?? emptyGuid);
    };
    table.defaultRuleId = this.pricingRulesManager.container.defaultPricingRuleId;

    return {
      template: () => html`${table}`,
      actions: () => table.getActions()
    };
  }
}
